import React from "react";

import Layout from "components/partials/Layout";
import BlogPost from "pages/Blog/components/BlogPost";

function Blog({ data }) {
  return (
    <Layout>
      <BlogPost post={data} />
    </Layout>
  );
}

export default Blog;
