import React from "react";
import { Heading, Text } from "grommet";

import { ContentfulTextLink } from "./ContentfulTextLink";
import { extractText } from "./utils";
import { link } from "./BlogPost/constants";

export function ContentfulTextField({ entry, ...rest }) {
  return (
    <Text {...rest}>
      {entry.content.map(({ nodeType, value, data, content }, i) => {
        if (nodeType === "text") {
          return value;
        }
        if (nodeType === link) {
          return (
            <ContentfulTextLink
              key={`${data.uri}-${i}`}
              uri={data.uri}
              text={extractText({ content })}
            />
          );
        }
        return null;
      })}
    </Text>
  );
}

export function ContentfulHeadingField({ entry, ...rest }) {
  const text = extractText(entry);
  return <Heading {...rest}>{text}</Heading>;
}
