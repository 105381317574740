import React from "react";
import { Grommet } from "grommet";

import theme from "theme";

function App({ children }) {
  return (
    <Grommet theme={theme} full>
      {children}
    </Grommet>
  );
}

export default App;
