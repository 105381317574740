import React from "react";
import { Box, Image } from "grommet";

const Hero = props => (
  <Box>
    <Image
      fill
      src="//images.ctfassets.net/qtrf25ya0fbp/4er3IXJvwWfZZ9dnQKcYgP/a4678e4cbcf12a0e204f2d1da5f9ba38/tokyo2020.jpg"
    />
  </Box>
);

export default Hero;
