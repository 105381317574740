import styled from "styled-components";
import Link from "found/lib/Link";
import { Box } from "grommet";

export const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: black;

  &:hover {
    h2 {
      transition: all 0.2s ease;
      color: ${({ theme }) => theme.global.colors.brand.light};
    }
    div {
      transition: all 0.2s ease;
      opacity: 0.8;
    }
  }
`;

export const Wrapper = styled(Box).attrs({ align: "center", margin: "xlarge" })`
  min-height: 100vh;
`;
