import head from "lodash.head";

const getImageDetails = ({ fields }) => ({
  title: fields.title,
  url: fields.file.url
});

const formatBlogPost = ({ fields: { title, slug, previewImage } }) => ({
  title,
  slug,
  previewImage: getImageDetails(previewImage)
});

export const extractBlogs = data =>
  head(data).fields.blogPost.map(formatBlogPost).reverse();

export const findBlogBySlug = (slug, blogs) =>
  extractBlogs(blogs).find(blog => blog.slug === slug);
