import React from "react";
import { Box, Heading } from "grommet";

function SignUp() {
  return (
    <Box align="center" background="brand" pad="xlarge">
      <Heading textAlign="center">
        Subscribe to stay up to date with what the Bou are up to
      </Heading>
      <link
        href="//cdn-images.mailchimp.com/embedcode/horizontal-slim-10_7.css"
        rel="stylesheet"
        type="text/css"
      />
      <div id="mc_embed_signup">
        <form
          action="https://team.us4.list-manage.com/subscribe/post?u=dad8c36a8cc6557c06811677c&amp;id=4550f7d8e3"
          method="post"
          id="mc-embedded-subscribe-form"
          name="mc-embedded-subscribe-form"
          className="validate"
          target="_blank"
          noValidate
        >
          <Box pad="small" id="mc_embed_signup_scroll">
            <input
              type="email"
              // value=""
              name="EMAIL"
              className="email"
              id="mce-EMAIL"
              placeholder="email address"
              required
            />
            <div
              style={{ position: "absolute", left: "-5000px" }}
              aria-hidden="true"
            >
              <input
                type="text"
                name="b_dad8c36a8cc6557c06811677c_4550f7d8e3"
                tabindex="-1"
              />
            </div>
            <Box pad="small" className="clear">
              <input
                type="submit"
                name="subscribe"
                id="mc-embedded-subscribe"
                className="button"
              />
            </Box>
          </Box>
        </form>
      </div>
    </Box>
  );
}

export default SignUp;
