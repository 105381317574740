import React from "react";
import { Image } from "grommet";

import { extractImage } from "./utils";

export function ContentfulImageField({ entry, ...rest }) {
  const { description, file } = extractImage(entry);
  const { url } = file;

  return <Image src={url} alt={description} {...rest} />;
}
