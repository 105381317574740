import React, { Fragment } from "react";

import Navigation from "components/partials/Navigation";
import Footer from "components/partials/Footer";

const Layout = ({ children }) => (
  <Fragment>
    <Navigation />
    {children}
    <Footer />
  </Fragment>
);

export default Layout;
