export default {
  name: "Red Caribou",
  rounding: 4,
  spacing: 24,
  defaultMode: "light",
  global: {
    colors: {
      brand: {
        light: "#fC0B24",
        dark: "#000000"
      },
      background: {
        dark: "#111111",
        light: "#FFFFFF"
      },
      "background-back": {
        dark: "#111111",
        light: "#EEEEEE"
      },
      "background-front": {
        dark: "#222222",
        light: "#FFFFFF"
      },
      "background-contrast": {
        dark: "#FFFFFF11",
        light: "#11111111"
      },
      text: {
        dark: "#EEEEEE",
        light: "#333333"
      },
      "text-strong": {
        dark: "#FFFFFF",
        light: "#000000"
      },
      "text-weak": {
        dark: "#CCCCCC",
        light: "#444444"
      },
      "text-xweak": {
        dark: "#999999",
        light: "#666666"
      },
      border: {
        dark: "#444444",
        light: "#CCCCCC"
      },
      control: "status-ok",
      "active-background": "background-contrast",
      "active-text": {
        light: "text-strong",
        dark: "#009FA3"
      },
      "selected-background": "brand",
      "selected-text": "text-strong",
      "status-critical": "#AF0000",
      "status-warning": "#006B69",
      "status-ok": "#5ABAB7",
      "status-unknown": "#CCCCCC",
      "status-disabled": "#CCCCCC",
      "graph-0": "brand",
      "graph-1": "status-warning"
    },
    font: {
      family: "Montserrat"
    },
    active: {
      background: "active-background",
      color: "active-text"
    },
    hover: {
      background: "active-background",
      color: "active-text"
    },
    selected: {
      background: "selected-background",
      color: "selected-text"
    }
  },
  layer: {
    background: {
      dark: "#111111",
      light: "#FFFFFF"
    }
  }
};
