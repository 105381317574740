import App from "App";

import Home from "pages/Home";
import Blog from "pages/Blog";
import Blogs from "pages/Blogs";
import Shop from "pages/Shop";

import blogs from ".content/blogs.json";
import blogsList from ".content/blog-list.json";

import resolver from "contentful/resolver";

export default [
  {
    path: "/",
    Component: App,
    children: [
      {
        Component: Home
      },
      {
        path: "/blog",
        Component: Blogs,
        getData: () => resolver(blogsList, "blogList")
      },
      {
        path: "/blog/:slug",
        Component: Blog,
        getData: async ({ params: { slug }, ...rest }) => {
          const data = await resolver(blogs, "blogs");
          return data.find(({ fields }) => fields.slug === slug);
        }
      },
      {
        path: "/shop",
        Component: Shop
      }
    ]
  }
];
