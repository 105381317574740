import React from "react";

import Layout from "components/partials/Layout";

function Shop() {
  return <Layout>shop</Layout>;
}

export default Shop;

