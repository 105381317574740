import React from "react";

import Layout from "components/partials/Layout";
import BlogCard from "pages/Blogs/components/BlogCard";
import { extractBlogs } from "./utils";

export function Blogs({ data }) {
  return (
    <Layout>
      {extractBlogs(data).map(({ slug, ...props }) => (
        <BlogCard key={slug} slug={slug} {...props} />
      ))}
    </Layout>
  );
}

export default Blogs;
