import React from "react";

import { Box, Heading, Image } from "grommet";
import { StyledLink, Wrapper } from "./BlogCard.styles";

function BlogCard({ title, slug, previewImage }) {
  const { imgTitle, url } = previewImage;
  return (
    <Wrapper>
      <StyledLink to={`/blog/${slug}`}>
        <Box justify="center" align="center" elevation="large">
          <Heading margin="medium" level={2}>
            {title}
          </Heading>
          <Box>
            <Image fill src={url} alt={imgTitle} />
          </Box>
        </Box>
      </StyledLink>
    </Wrapper>
  );
}

export default BlogCard;
