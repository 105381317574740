import styled from "styled-components";
import Link from "found/lib/Link";

export const StyledLink = styled(Link)`
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  border-left: 1px solid black;

  &:hover {
    color: ${({ theme }) => theme.global.colors.brand.light};
  }
`;

export const Logo = styled.img`
  height: 50px;
  width: 50px;
`;
