import resolveResponse from "contentful-resolve-response";
import snakeCase from "lodash.snakecase";
import contentManifest from ".content/manifest.json";

function constructQueryString(obj) {
  const keys = Object.keys(obj);
  if (keys.length > 0) {
    return keys
      .map(
        key =>
          `${encodeURIComponent(snakeCase(key))}=${encodeURIComponent(
            obj[key]
          )}`
      )
      .join("&");
  }
  return "";
}

const {
  REACT_APP_CONTENTFUL_API_URL: BASE_URL,
  REACT_APP_CONTENTFUL_ACCESS_TOKEN: ACCESS_TOKEN,
  // REACT_APP_STATIC_CONTENT: STATIC_CONTENT,
  // NODE_ENV
} = process.env;

async function resolver(staticData, queryName, manifest = contentManifest) {
  let data = staticData;
  // if (NODE_ENV === "development" || STATIC_CONTENT === "false") {
  if(true) {
    const { path, params } = manifest.queries.find(
      ({ name }) => name === queryName
    );

    const url = `${BASE_URL}${path}?${constructQueryString({
      ...params,
      accessToken: ACCESS_TOKEN
    })}`;

    const res = await fetch(url);
    data = await res.json();
  }
  return resolveResponse(data);
}

export default resolver;
