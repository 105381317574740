import React from "react";
import { Box, Heading } from "grommet";

import { ContentfulImageField } from "pages/Blog/components/ContentfulImageFields";
import {
  ContentfulHeadingField,
  ContentfulTextField
} from "pages/Blog/components/ContentfulTextFields";

import { p, h1, h2, h3, h4, h5, h6, img } from "./constants";

const componentFactory = (Component, additionalProps = {}) => ({
  Component,
  additionalProps: {
    margin: { bottom: "medium" },
    ...additionalProps
  }
});

const componentMap = {
  [p]: componentFactory(ContentfulTextField),
  [h1]: componentFactory(ContentfulHeadingField, { level: 1 }),
  [h2]: componentFactory(ContentfulHeadingField, { level: 2 }),
  [h3]: componentFactory(ContentfulHeadingField, { level: 3 }),
  [h4]: componentFactory(ContentfulHeadingField, { level: 4 }),
  [h5]: componentFactory(ContentfulHeadingField, { level: 5 }),
  [h6]: componentFactory(ContentfulHeadingField, { level: 6 }),
  [img]: componentFactory(ContentfulImageField, { fill: true })
};

function BlogPost({ post }) {
  const { fields } = post;
  const { body, title } = fields;
  const { content } = body;

  return (
    <Box margin="xlarge">
      <Heading margin={{ bottom: "medium" }}>{title}</Heading>
      <Box>
        {content.map(({ nodeType, ...rest }, i) => {
          const { Component, additionalProps } = componentMap[nodeType] || {};
          if (Component) {
            return <Component {...additionalProps} key={i} entry={rest} />;
          }
          return null;
        })}
      </Box>
    </Box>
  );
}

export default BlogPost;
