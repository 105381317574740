import createBrowserRouter from "found/lib/createBrowserRouter";
import React from "react";

import routeConfig from "routing";

const BrowserRouter = createBrowserRouter({
  routeConfig,

  renderError: ({ error }) => (
    <div>{error.status === 404 ? "Not found" : "Error"}</div>
  )
});

export default BrowserRouter;
