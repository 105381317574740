import React from "react";

import { Box, Text } from "grommet";
import Link from "found/lib/Link";

import LogoImg from "assets/images/logo.jpg";

import { Logo, StyledLink } from "./Navigation.styles";

const links = [
  {
    to: "/blog",
    text: "blog"
  }
  // {
  //   to: "/shop",
  //   text: "shop"
  // }
  // {
  //   to: "/partners",
  //   text: "partners"
  // },
  // {
  //   to: "/about",
  //   text: "about us"
  // }
];

const Navigation = props => (
  <Box
    tag="header"
    direction="row"
    align="center"
    justify="between"
    pad={{ left: "medium", right: "small", vertical: "xsmall" }}
    elevation="medium"
    style={{ zIndex: "1" }}
    {...props}
  >
    <Link to="/">
      <Box>
        <Logo src={LogoImg} alt="Red caribou logo" />
      </Box>
    </Link>
    <Box direction="row">
      {links.map(({ to, text }) => (
        <StyledLink key={to} to={to}>
          <Text margin={{ horizontal: "large" }}>{text}</Text>
        </StyledLink>
      ))}
    </Box>
  </Box>
);

export default Navigation;
