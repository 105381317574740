import React from "react";

import Layout from "components/partials/Layout";
import Hero from "components/partials/Hero";
import SignUp from "components/partials/SignUp";

function Home() {
  return (
    <Layout>
      <Hero />
      <SignUp />
    </Layout>
  );
}

export default Home;
