import React from "react";

import { Footer, Text, Anchor, Box } from "grommet";
import { Instagram, Mail } from "grommet-icons";

function SiteFooter() {
  return (
    <Footer background="brand" pad="medium">
      <Text>#RedCaribou</Text>
      <Box direction="row">
        <Anchor margin="small" href="mailto:mntredcaribou@gmail.com">
          <Mail color="white" size="medium" />
        </Anchor>
        <Anchor
          margin="small"
          target="_blank"
          href="https://www.instagram.com/fhc_mnt"
        >
          <Instagram color="white" size="medium" />
        </Anchor>
      </Box>
    </Footer>
  );
}

export default SiteFooter;
